import React, { useState } from "react";
import Logo from "../../images/periscope-works-logo-wordmark.png";
import TurvoLogo from "../../images/turvo-logo-black-crop.png";
import Login from "../Login/Login";
import { NavLink, useLocation } from "react-router-dom";

function Header(props) {
  const [showLogin, setShowLogin] = useState(false);
  const location = useLocation();
  console.log("location", location.pathname);
  return (
    <div id="header">
      <div className="logo-container">
        <NavLink to="/">
          <img id="logo" src={Logo} alt="periscope works logo" />
        </NavLink>
        {location.pathname === "/turvo" ? (
          <img id="turvo-logo" src={TurvoLogo} alt="turvo logo" />
        ) : null}
      </div>
      <div className="links">
        <NavLink to="/faqs">FAQs</NavLink>|
        <NavLink to="/contact">Contact</NavLink>
        {/* <a onClick={() => setShowLogin(true)}>Client Login</a> */}
      </div>
      {showLogin && <Login close={() => setShowLogin(false)} />}
    </div>
  );
}

export default Header;
