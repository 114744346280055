import React from "react";

function Contact(props) {
  return (
    <div id="contact">
      <div className="content">
        <div className="page-title">
          <h1>Have a project in mind?</h1>
        </div>
        <h3>We'd love to hear from you.</h3>
        <h3>
          <a href="mailto:info@periscopeworks.io">info@periscopeworks.io</a>
        </h3>
      </div>
    </div>
  );
}

export default Contact;
