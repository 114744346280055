import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Home from "../views/Home/Home";
import FAQs from "../views/FAQs/FAQs";
import Contact from "../views/Contact/Contact";
import Turvo from "../views/Turvo/Turvo";

// Global Styles
import "../styles/global.scss";

function AppRouter(props) {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/turvo" element={<Turvo />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default AppRouter;
