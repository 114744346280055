import React from "react";

function Footer(props) {
    return (
        <div id="footer">
            <div className="copyright"><p>&copy;2022 Periscope Works</p></div>
        </div>
    )
}

export default Footer;
