import React, { useState, useEffect } from "react";
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";

function Service(props) {
  const [showDesc, setShowDesc] = useState(false);
  const [descActive, setDescActive] = useState(false);

  const handleTitle = () => {
    setShowDesc(!showDesc);

    setTimeout(function () {
      setDescActive(!descActive);
    }, 200);
  };

  return (
    <div className={descActive ? "service active" : "service"}>
      <div
        className={descActive ? "title active" : "title"}
        onClick={() => handleTitle()}
      >
        <h2>{props.service.title}</h2>
      </div>

      {showDesc && (
        <div className={descActive ? "desc active" : "desc"}>
          <p>{props.service.desc}</p>
          {props.service.example && (
            <p>
              <label>Case Study</label>
              {props.service.example}
            </p>
          )}
          {props.service.links && (
            <p>
              Links:{" "}
              {props.service.links.map((item) => (
                <a href={item.link}>{item.title}</a>
              ))}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default Service;
