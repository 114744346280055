import React, { useState, useEffect } from "react";
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";

function FAQ(props) {
  const [level, setLevel] = useState(0);
  const [showDesc, setShowDesc] = useState(props.index === 0 ? true : false);
  const [descActive, setDescActive] = useState(
    props.index === 0 ? true : false
  );
  console.log("FAQ props", props);
  const handleTitle = () => {
    setShowDesc(!showDesc);

    setTimeout(function () {
      setDescActive(!descActive);
    }, 100);
  };

  return (
    <div
      key={props.key}
      className={
        descActive ? "question-container faq active" : "question-container faq"
      }
    >
      <div
        className={descActive ? "question active" : "question"}
        onClick={() => handleTitle()}
      >
        <h3>{props.faq.question}</h3>
      </div>

      {showDesc && (
        <div className={descActive ? "answer active" : "answer"}>
          <div dangerouslySetInnerHTML={{ __html: props.faq.answer }} />
        </div>
      )}
    </div>
  );
}

export default FAQ;
