import React from "react";
import { IoCloseOutline } from "react-icons/io5";



function Login(props) {
    return (
        <div id="login">

            <div className="close" onClick={() => props.close()}><IoCloseOutline /></div>

            <div className="form">

                <div className="form-item">
                    <label>Email</label>
                    <input></input>
                </div>
                <div className="form-item">
                    <label>Password</label>
                    <input></input>
                </div>
                <div className="form-item">
                    <input type="submit" value="Log In" />
                </div>
                <div className="form-item">
                    <p>Need help logging in?</p>
                </div>
            </div>



        </div>

    )
}

export default Login;
