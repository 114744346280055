import React, { useEffect, useState } from "react";
import TextTransition, { presets } from "react-text-transition";
import turvoLogo from "../../images/turvo-logo-black-crop.png";
function Turvo(props) {
  const [textIndex, setTextIndex] = useState(0);
  const textArray = [
    "CRM integrations?",
    "accounting systems?",
    "mailing lists?",
    "team management platforms?",
    "data streams?",
    "data visualizations?",
    "document signing needs?",
    "legacy systems?",
    "employee needs?",
  ];

  const colors = [
    "#e4572e",
    "#fe5f55",
    "#f7b32b",
    "#13c4a3",
    "#36f1cd",
    "#39a0ed",
    "#4c6085",
  ];

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTextIndex((prevState) => {
        return prevState + 1;
      });
    }, 4000);

    return () => clearTimeout(intervalID);
  }, []);
  return (
    <div id="turvo">
      <div className="content">
        <div className="page-title">
          <h1>
            Great, you've modernized your logistics with{" "}
            <img src={turvoLogo} alt="turvo" className="turvo-logo-inline" />{" "}
          </h1>
        </div>
        <div className="page-subtitle">
          <h2>
            But what about all your business systems and{" "}
            <TextTransition
              text={textArray[textIndex % textArray.length]}
              springConfig={presets.default}
              inline
              style={{
                color: colors[textIndex % colors.length],
              }}
            />
          </h2>
          <h2>We're here to help.</h2>
        </div>

        <div className="main-copy">
          <p>
            Periscope Works has extensive experience aggregating and integrating
            the various data streams, business logic, and client-facing
            requirements of growing organizations. We build products that
            simply, streamline, and standardize the way our clients operate
            their companies, whether that means working with existing solutions
            or creating administrative dashboards and tools from the ground up.
          </p>
          <p>
            Your new collaborative TMS solves the problem you needed it to
            solve, but you'd like to customize the integration, right? That's
            where we come in. Whether it's connecting your fuel surcharge API or
            needing a custom data transformation to connect your internal
            systems to Turvo, we can advise and build to your needs.
          </p>
          <p>
            We are a small team of dedicated developers and designers, and have
            worked in the shipping space for nearly 4 years. We are attuned to
            the needs of shippers, brokers, and 3PLs, and can speak the langauge
            of logistics.
          </p>
        </div>
        <h3>
          <a href="mailto:info@periscopeworks.io">info@periscopeworks.io</a>
        </h3>
      </div>
    </div>
  );
}

export default Turvo;
