import React, { useState, useEffect } from "react";
import FAQ from "./FAQ";

function FAQs(props) {
  const [theme, setTheme] = useState("theme1");
  const changeTheme = (selectedTheme) => {
    if (selectedTheme === "theme3") {
      alert("lol this doesn't work anymore");
      setTheme("theme2");
    } else {
      setTheme(selectedTheme);
    }
  };

  const faqs = [
    {
      question: "What are the benefits of custom software?",
      answer: `<p>You have <span>full ownership</span>. We create a proprietary app, 
    written to industry standards, and in modern, popular frameworks. 
    You gain the technical ability to customize, evolve, and scale with 
    your business, <span>tailor-made</span> to your workflows and unique 
    organizational challenges.</p>`,
    },
    {
      question: "What is your development process like?",
      answer: `<p>We do our most important work before writing a line of code, by 
        carefully planning each user interaction to design the most 
        efficient user interface and data structure for your current needs 
        and for the future.
        
        We prefer <span>Feature-Driven Development.</span> We believe in 
            flexible, iterative workflows organized around the features driving
            your user's experience.</p>`,
    },
    {
      question: "What technologies do you specialize in?",
      answer: `<p><ul>
      <li>
        Modern JavaScript technologies, including:
        <span>React, React Native, Swift, Vue,</span> and
        <span>Node</span>
      </li>
      <li>
        <span>PHP</span> and fully
        <span>custom WordPress development</span>, including plugins
        and database design 
      </li>
      <li>
        <span>PostgreSQL</span> and <span>MongoDB</span> (NoSQL)
        database systems
      </li>
      <li><span>REST</span> and <span>GraphQL APIs</span></li>
      <li><span>AWS</span> cloud ecosystem</li>
      <li><span>SalesForce</span> Apex Integrations</li>
    </ul></p>
      `,
    },
    {
      question: "How big is your company?",
      answer: `<p>We are <span>a core team of two</span>, which means your project will always
        receive our focused attention. We partner with <span>a trusted network of
        designers, developers, copywriters, and brand specialists</span> who share
        our commitment to excellent work, and who join us on a
        project-by-project basis depending on the scope and needs of the
        project.</p>`,
    },
    {
      question: "How do you handle maintenance and support?",
      answer:
        "<p>We respond to all issues within <span>two business days</span> (sooner for any\
            urgent issues) and will fix any errors related to the original\
            project scope free of charge. For ongoing maintenance, support, and\
            additional features, we offer maintenance packages in 20-hour blocks.</p>",
    },
  ];

  return (
    <div id={theme} className="faqs">
      {/* <section className="theme-button-container">
        <button onClick={() => changeTheme("theme1")}>Theme 1</button>
        <button onClick={() => changeTheme("theme2")}>Theme 2</button>
        <button onClick={() => changeTheme("theme3")}>Theme 3</button>
      </section> */}
      <section className="intro">
        <div className="page-title"><h1>frequently asked questions</h1></div>

      </section>
      <section className="questions">
        {faqs.map((faq, index) => {
          return <FAQ faq={faq} key={index} index={index} />;
        })}
      </section>
      {/* <section className="questions">
        <div className="question-container">
          <div className="question">Where is the best burrito in New York?</div>
          <div className="answer">
            To be honest, we never found an acceptable burrito that rose to the
            demands of our prior experiences. While we are always on the lookout
            for new burritos, you're better off getting tacos at Tacos El Bronco
            in Sunset Park or a slightly upscale lunch at Chavela's in Crown
            Heights.
          </div>
        </div>
        <div className="question-container">
          <div className="question">Okay, what about in San Francisco?</div>
          <div className="answer">
            We're open-minded and willing to entertain learned debate on the
            matter,
          </div>
        </div>
        <div className="question-container">
          <div className="question">Why do we have to do marketing?</div>
          <div className="answer">
            You have full ownership: A proprietary app, wherein we provide the
            code, written to industry standards, and in modern, popular
            frameworks; an ability to customize and evolve and scale with your
            business. Tailor-made to your workflows and unique organizational
            challenges
          </div>
        </div>
      </section> */}
      {/* <section className="intro">
        <h1>ORIGINAL:</h1>
        <h3>
          We see the big picture and take a human-first approach to everything
          from interface design to project management. People enjoy working with
          us because we enjoy people.
        </h3>
        <h3>
          Our experience in growth, strategy, and market research informs our
          engineering, empowering us to share your vision and solve your unique
          organizational challenges.
        </h3>
      </section> */}
    </div>
  );
}

export default FAQs;
