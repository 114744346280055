import React from "react";
import TurvoLogo from "../../images/turvo-logo-black-crop.png";

import Service from "./Service";
import { isNullableTypeAnnotation } from "@babel/types";

function Home(props) {
  const partners = [
    {
      companyName: "Turvo",
      img: TurvoLogo,
      url: "https://www.turvo.com/?utm_source=referral&utm_medium=periscopeworks&utm_campaign=periscopeworks",
    },
  ];
  const services = [
    {
      title: "Content Management Systems (CMS)",
      desc: "Cloud-based applications for creating, editing, and managing content when you want a more customized or streamlined solution than off-the-shelf solutions can provide.",
      example:
        "A non-profit tasked us with building a web, iOS, and Android app to assist social workers and community members in coordinating caregiving responsibilies for seniors. The final app offered journaling, document sharing, task assignments, and group calendars, and a directory of local resources, all tailored to the unique needs and concerns of caregiving.",
      links: [{ title: "Carenextion", link: "https://www.carenextion.org" }],
    },
    {
      title: "Mobile Apps",
      desc: "Multi-platform apps, built for reliability, speed, and maintainability.",
      example: null,
      links: [
        {
          title: "Ballerized Training",
          link: "https://apps.apple.com/us/app/ballerized-training/id1547464847",
        },
        {
          title: "Carenextion",
          link: "https://apps.apple.com/us/app/carenextion-mobile/id1570705580",
        },
      ],
    },
    {
      title: "Task Management Solutions",
      desc: "Simplification of time-wasting tasks like data entry, spreadsheet management, file organization, and document formatting.",
      example: null,
    },
    {
      title: "Third-Party Service Integrations",
      desc: "Centralized systems to simplify how you interact with the online services you already use day to day.",
      example:
        "A transportation and logistics company required a better way to manage their increasingly complex system of quotes, orders, and client accounts, which were updated on a minute-to-minute basis across multiple third party platforms. We created a custom administrative dashboard where employees could manage their workflow with greater ease and simplicity. Behind the scenes, their new API communicated with the many services they were previously handling with manual input and oversight, and offered public endpoints for clients who wished to use a REST API for their own front end experiences.",
      links: [
        { title: "Autovista", link: "https://autovista.mccollisters.com" },
      ],
    },

    {
      title: "Quote Calculators and E-Commerce Sites",
      desc: "Flexible quote calculators and e-commerce processes for complex transactions.",
      example:
        "A laundromat chain with over 20 locations hired us to build a new web app to centralize their online ordering process, in a more flexible and industry-specific way than their previous off-the-shelf platform. In addition to refreshing the customer ordering experience, we built an administrative portal to handle pricing, quoting, scheduling, and payment processing.",
      links: [
        { title: "Ms. Bubble Green", link: "https://www.msbubblegreen.com" },
      ],
    },
    {
      title: "Membership Platforms",
      desc: "Members-only digital content for paying customers.",
      example:
        "A professional speaker in the education space hired us to create a member platform where new updates, resources, and events could be posted regularly, with different members tiers receiving different content and interactive opportunities. Our client wanted to be able to fine-tune both the website and content reguarly using a familiar interface, so we built a custom theme and series of plugins on top of the WordPress engine to empower him to update content and manage members easily on his own.",
    },
  ];

  return (
    <div id="view-home">
      <section className="intro">
        <div className="page-title">
          <h1>
            bespoke software
            <br />
            for unique organizations
          </h1>
        </div>

        <div className="text-block">
          <h2>
            We are people people. We take a human-centered approach to
            everything from systems design to project management, because we
            believe that{" "}
            <span>every puzzle we solve must start with the user in mind.</span>
          </h2>
        </div>

        <div className="text-block">
          <h3>
            Our experience in growth, strategy, and market research informs our
            engineering, empowering us to share your vision and solve your
            unique business challenges.
          </h3>
        </div>
      </section>
      <section className="services">
        <h1>we build</h1>

        <div className="container">
          {services.map((service) => {
            return <Service service={service} />;
          })}
        </div>
      </section>
      <section className="services">
        <h1>we partner</h1>

        <div className="container">
          {partners.map((partner) => {
            return (
              <a href={`${partner.url}`}>
                <img
                  src={partner.img}
                  alt="Turvo"
                  style={{ maxWidth: 250, flex: 1 }}
                />
              </a>
            );
          })}
        </div>
      </section>
    </div>
  );
}

export default Home;
